import React from 'react';
import {Container, Navbar, NavbarBrand, Card, Row, Col
    , Collapse, Nav, NavItem, NavLink
} from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { FaPlus } from 'react-icons/fa';
import { AiOutlineHeart } from 'react-icons/ai';
import { GrHomeRounded } from 'react-icons/gr';
import { HiOutlineTicket } from 'react-icons/hi';
import { IoChevronBackSharp, IoAirplaneOutline, IoCarSportSharp } from 'react-icons/io5';
import { BiTrain } from 'react-icons/bi';
import { MdRestaurantMenu, MdOutlineHotel } from 'react-icons/md';
import ProfileImg from '../img/profile.png';

class Timeline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",


        };
        this.logout = this.logout.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.clickSlideOut = this.clickSlideOut.bind(this);

    }

    componentDidMount() {
        if(!localStorage.getItem("userId")){
            window.location.href = '/';
        }
        else{
            // this.getAllData();
        }
    }

    handleChange(e){
        switch(e.target.name) {
            case "search":
                this.setState({search : e.target.value});
                break;
            default:
            // code block
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    logout(){
        // this.setState({memberId: ""});
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
    }

    clickSlideOut(){
        var element = document.getElementById("slideout_inner");
        if(element.classList.contains("show") === false){
            element.classList.add("show");
        }
        else{
            element.classList.remove("show");
        }

    }

    static contextTypes = {
        router: () => true, // replace with PropTypes.object if you use them
    }

    render() {
        return (
            <div className="bg">
                <div id="slideout_inner" className="slideout d-flex d-md-none" onClick={this.clickSlideOut}>
                    <div className="spinner"></div>
                    <div className="body">
                        <a href="/home"><GrHomeRounded  className="home-icon"/></a>
                        <a href="/ticket"><HiOutlineTicket className="ticket-icon"/></a>
                    </div>
                </div>
                <Container>
                <Navbar color="white" light sticky="top" >
                    <div>
                        {(localStorage.getItem("imageUrl")!=="null") ? <NavbarBrand role="button" onClick={this.toggle}><img src={localStorage.getItem("imageUrl")} className="profile-img" alt="Profile" /></NavbarBrand> : <NavbarBrand role="button" onClick={this.toggle}><img src={ProfileImg} className="profile-img" alt="Profile" /></NavbarBrand>}
                        {(localStorage.getItem("username")) && <div className="d-none d-md-inline-flex fw-bold">{localStorage.getItem("username")}</div>}
                        <div className="d-none d-md-inline-flex mx-3"><a href="/ticket"><HiOutlineTicket className="red-circle-border"/></a></div>
                    </div>
                    <a href="/post" className="d-flex"><FaPlus className="red-circle-border"/></a>
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <NavLink> <button className="btn btn-dark circle-border" onClickCapture={this.logout}>Logout</button></NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
                </Container>

                <div>
                    <Container className="py-5">
                        <div className="row">
                            <div className="col-3"><a href="/home"><IoChevronBackSharp className="red-circle-border"/></a></div>
                            <div className="col-9 text-right">
                                <h1 className="gray">
                                    Sydney trip plan
                                </h1>
                            </div>
                        </div>

                        <div className="row pt-2">
                            <div className="col-12 pt-2">
                                <div className="row">
                                    <div className="col-9">
                                        <h2>Sydney</h2>
                                    </div>
                                    <div className="col-3 text-right">
                                        <h5 className="pt-1"><AiOutlineHeart/><span>120k</span></h5>
                                    </div>
                                </div>
                                <small className="text-gray">10 Jan 2023</small>
                                <p>The Beatles were an English rock band, formed in Liverpool in 1960, that comprised John Lennon, Paul McCartney, George Harrison and Ringo Starr. They are regarded</p>
                            </div>
                        </div>



                        <Tabs className="Tabs">

                            <div className="overflow-x-auto">
                                <TabList>
                                    <Tab>Day 1</Tab>
                                    <Tab>Day 2</Tab>
                                    <Tab>Day 3</Tab>
                                    <Tab>Day 4</Tab>
                                    <Tab>Day 5</Tab>
                                </TabList>
                            </div>
                            <TabPanel>
                                <div className="row">
                                    <div className="col-2">
                                        <div className="line-timeline"> </div>

                                    </div>
                                    <div className="col-10">
                                        <Card body className="circle-border border-0 my-4 bg-gray">
                                            <Row className="my-2 h-100">
                                                <Col xs="12" sm="12">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <IoAirplaneOutline className="icon-transport-flight" />
                                                            {/*<BiTrain className="icon-transport" />*/}
                                                            {/*<IoCarSportSharp className="icon-transport" />*/}

                                                            {/*<MdRestaurantMenu className="icon-eat" />*/}
                                                            {/*<MdOutlineHotel className="icon-hotel" />*/}
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <h5 className="data-timeline">Flight</h5>
                                                                </div>
                                                                <div className="col-8 text-right">
                                                                    <p className="text-timeline">8:30 AM</p>
                                                                </div>
                                                                <div className="col-4">
                                                                    <p className="text-timeline">From</p>
                                                                </div>
                                                                <div className="col-8 text-right">
                                                                    <h5 className="data-timeline">Sydney</h5>
                                                                </div>
                                                                <div className="col-4">
                                                                    <p className="text-timeline">To</p>
                                                                </div>
                                                                <div className="col-8 text-right">
                                                                    <h5 className="data-timeline">Thai</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>

                                        <Card body className="circle-border border-0 my-4 bg-gray">
                                            <Row className="my-2 h-100">
                                                <Col xs="12" sm="12">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            {/*<IoAirplaneOutline className="icon-transport" />*/}
                                                            <BiTrain className="icon-transport-train" />
                                                            {/*<IoCarSportSharp className="icon-transport" />*/}

                                                            {/*<MdRestaurantMenu className="icon-eat" />*/}
                                                            {/*<MdOutlineHotel className="icon-hotel" />*/}
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <h5 className="data-timeline">Train</h5>
                                                                </div>
                                                                <div className="col-8 text-right">
                                                                    <p className="text-timeline">11:00 AM</p>
                                                                </div>
                                                                <div className="col-4">
                                                                    <p className="text-timeline">From</p>
                                                                </div>
                                                                <div className="col-8 text-right">
                                                                    <h5 className="data-timeline">Sydney Airport</h5>
                                                                </div>
                                                                <div className="col-4">
                                                                    <p className="text-timeline">To</p>
                                                                </div>
                                                                <div className="col-8 text-right">
                                                                    <h5 className="data-timeline">Sydney CBD</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>

                                        <Card body className="circle-border border-0 my-4 bg-gray">
                                            <Row className="my-2 h-100">
                                                <Col xs="12" sm="12">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            {/*<IoAirplaneOutline className="icon-transport" />*/}
                                                            {/*<BiTrain className="icon-transport" />*/}
                                                            <IoCarSportSharp className="icon-transport-car" />

                                                            {/*<MdRestaurantMenu className="icon-eat" />*/}
                                                            {/*<MdOutlineHotel className="icon-hotel" />*/}
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <h5 className="data-timeline">Car Rent</h5>
                                                                </div>
                                                                <div className="col-6 text-right">
                                                                    <p className="text-timeline">13:00 PM</p>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p className="text-timeline">Check in</p>
                                                                </div>
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <h5 className="data-ticket">Sydney Airport</h5>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <p className="text-ticket">To</p>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <h5 className="data-ticket">Sydney CBD</h5>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>

                                        <Card body className="circle-border border-0 my-4 bg-gray">
                                            <Row className="my-2 h-100">
                                                <Col xs="12" sm="12">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            {/*<IoAirplaneOutline className="icon-transport" />*/}
                                                            {/*<BiTrain className="icon-transport" />*/}
                                                            {/*<IoCarSportSharp className="icon-transport" />*/}

                                                            <MdRestaurantMenu className="icon-eat" />
                                                            {/*<MdOutlineHotel className="icon-hotel" />*/}
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <h5 className="data-timeline">Diner</h5>
                                                                </div>
                                                                <div className="col-6 text-right">
                                                                    <p className="text-timeline">13:00 PM</p>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p className="text-timeline">Check in</p>
                                                                </div>
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <h5 className="data-ticket">Sydney Airport</h5>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <p className="text-ticket">To</p>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <h5 className="data-ticket">Sydney CBD</h5>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>

                                        <Card body className="circle-border border-0 my-4 bg-gray">
                                            <Row className="my-2 h-100">
                                                <Col xs="12" sm="12">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            {/*<IoAirplaneOutline className="icon-transport" />*/}
                                                            {/*<BiTrain className="icon-transport" />*/}
                                                            {/*<IoCarSportSharp className="icon-transport" />*/}

                                                            {/*<MdRestaurantMenu className="icon-eat" />*/}
                                                            <MdOutlineHotel className="icon-hotel" />
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <h5 className="data-timeline">Hotel</h5>
                                                                </div>
                                                                <div className="col-6 text-right">
                                                                    <p className="text-timeline">13:00 PM</p>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p className="text-timeline">Check in</p>
                                                                </div>
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <h5 className="data-ticket">Sydney Airport</h5>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <p className="text-ticket">To</p>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <h5 className="data-ticket">Sydney CBD</h5>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row">
                                    <div className="col-2">
                                        <div className="line-timeline"> </div>

                                    </div>
                                    <div className="col-10">
                                        <Card body className="circle-border border-0 my-4 bg-gray">
                                            <Row className="my-2 h-100">
                                                <Col xs="12" sm="12">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            {/*<IoAirplaneOutline className="icon-transport" />*/}
                                                            {/*<BiTrain className="icon-transport" />*/}
                                                            <IoCarSportSharp className="icon-transport-car" />

                                                            {/*<MdRestaurantMenu className="icon-eat" />*/}
                                                            {/*<MdOutlineHotel className="icon-hotel" />*/}
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <h5 className="data-timeline">Car Rent</h5>
                                                                </div>
                                                                <div className="col-6 text-right">
                                                                    <p className="text-timeline">13:00 PM</p>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p className="text-timeline">Check in</p>
                                                                </div>
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <h5 className="data-ticket">Sydney Airport</h5>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <p className="text-ticket">To</p>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <h5 className="data-ticket">Sydney CBD</h5>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>

                                        <Card body className="circle-border border-0 my-4 bg-gray">
                                            <Row className="my-2 h-100">
                                                <Col xs="12" sm="12">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            {/*<IoAirplaneOutline className="icon-transport" />*/}
                                                            {/*<BiTrain className="icon-transport" />*/}
                                                            {/*<IoCarSportSharp className="icon-transport" />*/}

                                                            <MdRestaurantMenu className="icon-eat" />
                                                            {/*<MdOutlineHotel className="icon-hotel" />*/}
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <h5 className="data-timeline">Diner</h5>
                                                                </div>
                                                                <div className="col-6 text-right">
                                                                    <p className="text-timeline">13:00 PM</p>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p className="text-timeline">Check in</p>
                                                                </div>
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <h5 className="data-ticket">Sydney Airport</h5>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <p className="text-ticket">To</p>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <h5 className="data-ticket">Sydney CBD</h5>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>

                                        <Card body className="circle-border border-0 my-4 bg-gray">
                                            <Row className="my-2 h-100">
                                                <Col xs="12" sm="12">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            {/*<IoAirplaneOutline className="icon-transport" />*/}
                                                            {/*<BiTrain className="icon-transport" />*/}
                                                            {/*<IoCarSportSharp className="icon-transport" />*/}

                                                            {/*<MdRestaurantMenu className="icon-eat" />*/}
                                                            <MdOutlineHotel className="icon-hotel" />
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <h5 className="data-timeline">Hotel</h5>
                                                                </div>
                                                                <div className="col-6 text-right">
                                                                    <p className="text-timeline">13:00 PM</p>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p className="text-timeline">Check in</p>
                                                                </div>
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <h5 className="data-ticket">Sydney Airport</h5>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <p className="text-ticket">To</p>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <h5 className="data-ticket">Sydney CBD</h5>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row">
                                    <div className="col-2">
                                        <div className="line-timeline"> </div>

                                    </div>
                                    <div className="col-10">
                                        <Card body className="circle-border border-0 my-4 bg-gray">
                                            <Row className="my-2 h-100">
                                                <Col xs="12" sm="12">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <IoAirplaneOutline className="icon-transport-flight" />
                                                            {/*<BiTrain className="icon-transport" />*/}
                                                            {/*<IoCarSportSharp className="icon-transport" />*/}

                                                            {/*<MdRestaurantMenu className="icon-eat" />*/}
                                                            {/*<MdOutlineHotel className="icon-hotel" />*/}
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <h5 className="data-timeline">Flight</h5>
                                                                </div>
                                                                <div className="col-8 text-right">
                                                                    <p className="text-timeline">8:30 AM</p>
                                                                </div>
                                                                <div className="col-4">
                                                                    <p className="text-timeline">From</p>
                                                                </div>
                                                                <div className="col-8 text-right">
                                                                    <h5 className="data-timeline">Sydney</h5>
                                                                </div>
                                                                <div className="col-4">
                                                                    <p className="text-timeline">To</p>
                                                                </div>
                                                                <div className="col-8 text-right">
                                                                    <h5 className="data-timeline">Thai</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>

                                        <Card body className="circle-border border-0 my-4 bg-gray">
                                            <Row className="my-2 h-100">
                                                <Col xs="12" sm="12">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            {/*<IoAirplaneOutline className="icon-transport" />*/}
                                                            <BiTrain className="icon-transport-train" />
                                                            {/*<IoCarSportSharp className="icon-transport" />*/}

                                                            {/*<MdRestaurantMenu className="icon-eat" />*/}
                                                            {/*<MdOutlineHotel className="icon-hotel" />*/}
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <h5 className="data-timeline">Train</h5>
                                                                </div>
                                                                <div className="col-8 text-right">
                                                                    <p className="text-timeline">11:00 AM</p>
                                                                </div>
                                                                <div className="col-4">
                                                                    <p className="text-timeline">From</p>
                                                                </div>
                                                                <div className="col-8 text-right">
                                                                    <h5 className="data-timeline">Sydney Airport</h5>
                                                                </div>
                                                                <div className="col-4">
                                                                    <p className="text-timeline">To</p>
                                                                </div>
                                                                <div className="col-8 text-right">
                                                                    <h5 className="data-timeline">Sydney CBD</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>

                                        <Card body className="circle-border border-0 my-4 bg-gray">
                                            <Row className="my-2 h-100">
                                                <Col xs="12" sm="12">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            {/*<IoAirplaneOutline className="icon-transport" />*/}
                                                            {/*<BiTrain className="icon-transport" />*/}
                                                            <IoCarSportSharp className="icon-transport-car" />

                                                            {/*<MdRestaurantMenu className="icon-eat" />*/}
                                                            {/*<MdOutlineHotel className="icon-hotel" />*/}
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <h5 className="data-timeline">Car Rent</h5>
                                                                </div>
                                                                <div className="col-6 text-right">
                                                                    <p className="text-timeline">13:00 PM</p>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p className="text-timeline">Check in</p>
                                                                </div>
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <h5 className="data-ticket">Sydney Airport</h5>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <p className="text-ticket">To</p>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <h5 className="data-ticket">Sydney CBD</h5>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>

                                        <Card body className="circle-border border-0 my-4 bg-gray">
                                            <Row className="my-2 h-100">
                                                <Col xs="12" sm="12">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            {/*<IoAirplaneOutline className="icon-transport" />*/}
                                                            {/*<BiTrain className="icon-transport" />*/}
                                                            {/*<IoCarSportSharp className="icon-transport" />*/}

                                                            <MdRestaurantMenu className="icon-eat" />
                                                            {/*<MdOutlineHotel className="icon-hotel" />*/}
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <h5 className="data-timeline">Diner</h5>
                                                                </div>
                                                                <div className="col-6 text-right">
                                                                    <p className="text-timeline">13:00 PM</p>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p className="text-timeline">Check in</p>
                                                                </div>
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <h5 className="data-ticket">Sydney Airport</h5>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <p className="text-ticket">To</p>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <h5 className="data-ticket">Sydney CBD</h5>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>

                                        <Card body className="circle-border border-0 my-4 bg-gray">
                                            <Row className="my-2 h-100">
                                                <Col xs="12" sm="12">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            {/*<IoAirplaneOutline className="icon-transport" />*/}
                                                            {/*<BiTrain className="icon-transport" />*/}
                                                            {/*<IoCarSportSharp className="icon-transport" />*/}

                                                            {/*<MdRestaurantMenu className="icon-eat" />*/}
                                                            <MdOutlineHotel className="icon-hotel" />
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <h5 className="data-timeline">Hotel</h5>
                                                                </div>
                                                                <div className="col-6 text-right">
                                                                    <p className="text-timeline">13:00 PM</p>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p className="text-timeline">Check in</p>
                                                                </div>
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <h5 className="data-ticket">Sydney Airport</h5>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <p className="text-ticket">To</p>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-6 mt-2">*/}
                                                                {/*    <h5 className="data-ticket">Sydney CBD</h5>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>





                    </Container>
                </div>
            </div>
        );
    }
}
export default Timeline