import React from 'react';
import {
    Container, Navbar, NavbarBrand, Card, Row, Col
    , Collapse, Nav, NavItem, NavLink, Input
} from 'reactstrap';

import { FaPlus } from 'react-icons/fa';
import {FiCompass, FiSearch} from 'react-icons/fi';
import { GrHomeRounded } from 'react-icons/gr';
import { HiOutlineTicket, HiOutlineLocationMarker } from 'react-icons/hi';
import { IoNavigateOutline, IoAirplaneSharp } from 'react-icons/io5';
import { SlLocationPin } from 'react-icons/sl';
import { CgArrowsExchangeAlt } from 'react-icons/cg';

import ProfileImg from '../img/profile.png';
import Qrcode from '../img/qrcode-mock.PNG';
import Logo from "../img/logo_new.png";

class Ticket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",


        };
        this.logout = this.logout.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.clickSlideOut = this.clickSlideOut.bind(this);

    }

    componentDidMount() {
        if(!localStorage.getItem("userId")){
            window.location.href = '/';
        }
        else{
            // this.getAllData();
        }
    }

    handleChange(e){
        switch(e.target.name) {
            case "search":
                this.setState({search : e.target.value});
                break;
            default:
            // code block
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    logout(){
        // this.setState({memberId: ""});
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
    }

    clickSlideOut(){
        var element = document.getElementById("slideout_inner");
        if(element.classList.contains("show") === false){
            element.classList.add("show");
        }
        else{
            element.classList.remove("show");
        }

    }

    render() {
        return (
            <div className="bg">
                <div id="slideout_inner" className="slideout d-flex d-md-none" onClick={this.clickSlideOut}>
                    <div className="spinner"></div>
                    <div className="body">
                        <a href="/home"><GrHomeRounded  className="home-icon"/></a>
                        <a href="/ticket"><HiOutlineTicket className="ticket-icon"/></a>
                    </div>
                </div>
                <Container>
                    {/*Desktop show Navbar*/}
                    <Container className="d-none d-lg-block">
                        <Navbar color="transparent" sticky="top" >
                            <div>
                                <NavbarBrand role="button" href="/"><img src={Logo} className="logo-img" alt="Profile" /></NavbarBrand><div className="d-none d-md-inline-flex fw-bold">Fridayis</div>
                                {/*{(localStorage.getItem("imageUrl")) ? <NavbarBrand role="button" onClick={this.toggle}><img src={localStorage.getItem("imageUrl")} className="profile-img" alt="Profile" /></NavbarBrand> : <NavbarBrand role="button" onClick={this.toggle}><img src={ProfileImg} className="profile-img" alt="Profile" /></NavbarBrand>}*/}
                                {/*{(localStorage.getItem("username")) && <div className="d-none d-md-inline-flex fw-bold">{localStorage.getItem("username")}</div>}*/}
                                <div className="d-none d-md-inline-flex mx-4"><a href="/ticket"><HiOutlineTicket className="red-circle-border"/></a></div>
                            </div>
                            {/*<NavbarToggler onClick={this.toggle} />*/}
                            <div className="d-flex">
                                <div className="input-group mx-3">
                                    <Input className="right-padding-input circle-border form-control-sm border" type="text" name="search" id="search" value={this.state.search} onChange={this.handleChange} onKeyDown={(e)=>this.checkEnter(e)}/>
                                    <span className="position-absolute right-margin pr-1 bg-transparent border-0 border">
                                <FiSearch />
                            </span>
                                </div>
                                <a href="/post"><FaPlus className="red-circle-border"/></a>
                                {(localStorage.getItem("imageUrl")) ? <NavbarBrand role="button" onClick={this.toggle}><img src={localStorage.getItem("imageUrl")} className="profile-img" alt="Profile" /></NavbarBrand> : <NavbarBrand role="button" onClick={this.toggle}><img src={ProfileImg} className="profile-img" alt="Profile" /></NavbarBrand>}
                            </div>

                            {/*className="d-sm-flex d-xs-flex d-xxs-flex d-md-none"*/}
                            <Collapse isOpen={this.state.isOpen} navbar>
                                <Nav className="ml-auto nav-logout" navbar>
                                    <NavItem>
                                        <NavLink> <button className="btn btn-dark circle-border" onClickCapture={this.logout}>Logout</button></NavLink>
                                    </NavItem>
                                </Nav>
                            </Collapse>
                        </Navbar>
                    </Container>
                    <Navbar className="d-block d-lg-none" color="white" transparent sticky="top" >
                        <div>
                            {/*<NavbarBrand role="button" href="/"><img src={Logo} className="logo-img" alt="Profile" /></NavbarBrand>*/}
                            {(localStorage.getItem("imageUrl")) ? <NavbarBrand role="button" onClick={this.toggle}><img src={localStorage.getItem("imageUrl")} className="profile-img" alt="Profile" /></NavbarBrand> : <NavbarBrand role="button" onClick={this.toggle}><img src={ProfileImg} className="profile-img" alt="Profile" /></NavbarBrand>}
                            {/*{(localStorage.getItem("username")) && <div className="d-none d-md-inline-flex fw-bold">{localStorage.getItem("username")}</div>}*/}
                            <div className="d-none d-md-inline-flex mx-3"><a href="/ticket"><HiOutlineTicket className="red-circle-border"/></a></div>
                        </div>
                        <div className="d-flex">
                            <a href="/post"><FaPlus className="red-circle-border"/></a>
                            {/*{(localStorage.getItem("imageUrl")) ? <NavbarBrand role="button"><img src={localStorage.getItem("imageUrl")} className="profile-img" alt="Profile" /></NavbarBrand> : <NavbarBrand role="button"><img src={ProfileImg} className="profile-img" alt="Profile" /></NavbarBrand>}*/}
                        </div>

                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="ml-auto text-left" navbar>
                                <NavItem>
                                    <NavLink> <button className="btn btn-dark circle-border" onClickCapture={this.logout}>Logout</button></NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Navbar>
                {/*<Navbar color="white" light sticky="top" >*/}
                {/*    <div>*/}
                {/*        <NavbarBrand role="button" href="/"><img src={Logo} className="logo-img" alt="Profile" /></NavbarBrand><div className="d-none d-md-inline-flex fw-bold">Fridayis</div>*/}
                {/*        /!*{(localStorage.getItem("imageUrl")!=="null") ? <NavbarBrand role="button" onClick={this.toggle}><img src={localStorage.getItem("imageUrl")} className="profile-img" alt="Profile" /></NavbarBrand> : <NavbarBrand role="button" onClick={this.toggle}><img src={ProfileImg} className="profile-img" alt="Profile" /></NavbarBrand>}*!/*/}
                {/*        /!*{(localStorage.getItem("username")) && <div className="d-none d-md-inline-flex fw-bold">{localStorage.getItem("username")}</div>}*!/*/}
                {/*        <div className="d-none d-md-inline-flex mx-4"><a href="/ticket"><HiOutlineTicket className="red-circle-border"/></a></div>*/}
                {/*    </div>*/}
                {/*    <div className="d-flex">*/}
                {/*    <a href="/post" className="d-flex"><FaPlus className="red-circle-border"/></a>*/}
                {/*    {(localStorage.getItem("imageUrl")) ? <NavbarBrand role="button" onClick={this.toggle}><img src={localStorage.getItem("imageUrl")} className="profile-img" alt="Profile" /></NavbarBrand> : <NavbarBrand role="button" onClick={this.toggle}><img src={ProfileImg} className="profile-img" alt="Profile" /></NavbarBrand>}*/}
                {/*    </div>*/}
                {/*    <Collapse isOpen={this.state.isOpen} navbar>*/}
                {/*        <Nav className="ml-auto nav-logout" navbar>*/}
                {/*            <NavItem>*/}
                {/*                <NavLink> <button className="btn btn-dark circle-border" onClickCapture={this.logout}>Logout</button></NavLink>*/}
                {/*            </NavItem>*/}
                {/*        </Nav>*/}
                {/*    </Collapse>*/}
                {/*</Navbar>*/}
                </Container>

                <div>
                    <Container className="py-5">
                        <h1 className="underline">
                           Book flight
                        </h1>
                        {/*border-0 mx-2*/}

                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <Card body className="circle-border border-0 my-4">
                                    <Row className="my-2 h-100">
                                        <Col xs="12" sm="12">
                                            <div className="row">
                                                <div className="col-2">
                                                    <div className="icon-location-group">
                                                        <FiCompass />
                                                        <HiOutlineLocationMarker className="icon-pin"/>
                                                        <div className="line-dot-y"> </div>
                                                    </div>

                                                </div>
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-12 line-dot-x">
                                                            <p className="text-ticket my-3">From</p>
                                                            <h5 className="data-ticket-red">Sydney</h5>
                                                        </div>
                                                        <div className="col-12 my-4">
                                                            <p className="text-ticket my-3">To</p>
                                                            <h5 className="data-ticket-red">Thai</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-2 text-center">
                                                    <CgArrowsExchangeAlt className="icon-switch"/>
                                                </div>
                                            </div>
                                            {/*<span className="bold">{this.state.name}, {this.state.position}</span> <br/> <small>Last update {this.state.timeUpdate}</small>*/}
                                        </Col>
                                    </Row>

                                </Card>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <Card body className="circle-border border-0 my-4">
                                    <Row className="">
                                        <Col xs="12" sm="12">
                                            <div className="row">
                                                <div className="col-6 text-center">
                                                    <p className="trip">One Way</p>
                                                </div>
                                                <div className="col-6 text-center">
                                                    <p className="trip active">Return Trip</p>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="text-ticket my-2">Departure</p>
                                                    <h5 className="data-ticket-red">14 Feb 2022</h5>
                                                </div>
                                                <div className="col-6 text-right my-2">
                                                    <p className="text-ticket">Time</p>
                                                    <h5 className="data-ticket-red">09.59</h5>
                                                </div>
                                            </div>
                                            <div className="row my-2">
                                                <div className="col-6 my-2">
                                                    <p className="text-ticket">Class</p>
                                                    <h5 className="data-ticket-red">Economy</h5>
                                                </div>
                                                <div className="col-6 text-right my-2">
                                                    <p className="text-ticket">Passenger</p>
                                                    <h5 className="data-ticket-red">2 Adult</h5>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <button type="button" className="btn btn-primary button-ticket form-control">Search Flights</button>
                                            </div>
                                            {/*<span className="bold">{this.state.name}, {this.state.position}</span> <br/> <small>Last update {this.state.timeUpdate}</small>*/}
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        </div>




                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-8">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <div className="cardWrap">
                                            <div className="card cardLeft card-ticket my-auto border-0">
                                                <div className="mb-2">
                                                    <h4 className="title">Ansett Pioneer</h4>
                                                </div>


                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <IoNavigateOutline className="icon-city" />
                                                    </div>
                                                    <div className="col-9">
                                                        <h5 className="city mb-0">Melbourne</h5>
                                                        <span className="date">15-Dec-2020</span>
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <SlLocationPin className="icon-city" />
                                                    </div>
                                                    <div className="col-9">
                                                        <h5 className="city mb-0">Sydney</h5>
                                                        <span className="date">15-Dec-2020</span>
                                                    </div>
                                                </div>

                                                {/*<div className="name">*/}
                                                {/*    <h2>Vladimir Kudinov</h2>*/}
                                                {/*    <span>name</span>*/}
                                                {/*</div>*/}
                                                {/*<div className="seat">*/}
                                                {/*    <h2>156</h2>*/}
                                                {/*    <span>seat</span>*/}
                                                {/*</div>*/}
                                                {/*<div className="time">*/}
                                                {/*    <h2>12:00</h2>*/}
                                                {/*    <span>time</span>*/}
                                                {/*</div>*/}

                                            </div>
                                            <div className="card cardRight card-ticket my-auto">

                                                <div className="mb-2 text-center">
                                                    <h4 className="title">9:00 AM</h4>
                                                </div>

                                                <button type="button" className="btn btn-primary button-ticket">Buy Ticket</button>

                                                <div className="mt-3 text-center">
                                                    <h5 className="price">Price: <span className="red">$70</span></h5>
                                                </div>

                                                {/*<div className="number">*/}
                                                {/*    <h3>156</h3>*/}
                                                {/*    <span>seat</span>*/}
                                                {/*</div>*/}
                                                {/*<div className="barcode"></div>*/}

                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <div className="cardWrap">
                                            <div className="card cardLeft card-ticket my-auto border-0">
                                                <div className="mb-2">
                                                    <h4 className="title">Ansett Pioneer</h4>
                                                </div>


                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <IoNavigateOutline className="icon-city" />
                                                    </div>
                                                    <div className="col-9">
                                                        <h5 className="city mb-0">Melbourne</h5>
                                                        <span className="date">15-Dec-2020</span>
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <SlLocationPin className="icon-city" />
                                                    </div>
                                                    <div className="col-9">
                                                        <h5 className="city mb-0">Sydney</h5>
                                                        <span className="date">15-Dec-2020</span>
                                                    </div>
                                                </div>

                                                {/*<div className="name">*/}
                                                {/*    <h2>Vladimir Kudinov</h2>*/}
                                                {/*    <span>name</span>*/}
                                                {/*</div>*/}
                                                {/*<div className="seat">*/}
                                                {/*    <h2>156</h2>*/}
                                                {/*    <span>seat</span>*/}
                                                {/*</div>*/}
                                                {/*<div className="time">*/}
                                                {/*    <h2>12:00</h2>*/}
                                                {/*    <span>time</span>*/}
                                                {/*</div>*/}

                                            </div>
                                            <div className="card cardRight card-ticket my-auto">

                                                <div className="mb-2 text-center">
                                                    <h4 className="title">9:00 AM</h4>
                                                </div>

                                                <button type="button" className="btn btn-primary button-ticket">Buy Ticket</button>

                                                <div className="mt-3 text-center">
                                                    <h5 className="price">Price: <span className="red">$70</span></h5>
                                                </div>

                                                {/*<div className="number">*/}
                                                {/*    <h3>156</h3>*/}
                                                {/*    <span>seat</span>*/}
                                                {/*</div>*/}
                                                {/*<div className="barcode"></div>*/}

                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <div className="cardWrap">
                                            <div className="card cardLeft card-ticket my-auto border-0">
                                                <div className="mb-2">
                                                    <h4 className="title">Ansett Pioneer</h4>
                                                </div>


                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <IoNavigateOutline className="icon-city" />
                                                    </div>
                                                    <div className="col-9">
                                                        <h5 className="city mb-0">Melbourne</h5>
                                                        <span className="date">15-Dec-2020</span>
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <SlLocationPin className="icon-city" />
                                                    </div>
                                                    <div className="col-9">
                                                        <h5 className="city mb-0">Sydney</h5>
                                                        <span className="date">15-Dec-2020</span>
                                                    </div>
                                                </div>

                                                {/*<div className="name">*/}
                                                {/*    <h2>Vladimir Kudinov</h2>*/}
                                                {/*    <span>name</span>*/}
                                                {/*</div>*/}
                                                {/*<div className="seat">*/}
                                                {/*    <h2>156</h2>*/}
                                                {/*    <span>seat</span>*/}
                                                {/*</div>*/}
                                                {/*<div className="time">*/}
                                                {/*    <h2>12:00</h2>*/}
                                                {/*    <span>time</span>*/}
                                                {/*</div>*/}

                                            </div>
                                            <div className="card cardRight card-ticket my-auto">

                                                <div className="mb-2 text-center">
                                                    <h4 className="title">9:00 AM</h4>
                                                </div>

                                                <button type="button" className="btn btn-primary button-ticket">Buy Ticket</button>

                                                <div className="mt-3 text-center">
                                                    <h5 className="price">Price: <span className="red">$70</span></h5>
                                                </div>

                                                {/*<div className="number">*/}
                                                {/*    <h3>156</h3>*/}
                                                {/*    <span>seat</span>*/}
                                                {/*</div>*/}
                                                {/*<div className="barcode"></div>*/}

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <div className="cardWrapTicket">
                                    <div className="card cardTop border-0">
                                        <div className="row my-2">
                                            <div className="col-6">
                                                <p className="text-ticket">Voo</p>
                                                <h5 className="data-ticket">RS995</h5>
                                            </div>
                                            <div className="col-6 text-right">
                                                <p className="text-ticket">Data</p>
                                                <h5 className="data-ticket">23/05/2023</h5>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="row my-2">
                                            <div className="col-6">
                                                <p className="text-ticket">São Paulo, Brasil</p>
                                                <h2 className="data-ticket-thick">GRU</h2>
                                                <p className="text-ticket">17:00</p>
                                                <h3 className="airplan-icon"><IoAirplaneSharp/></h3>
                                            </div>
                                            <div className="col-6 text-right">
                                                <p className="text-ticket">São Francisco, EUA</p>
                                                <h2 className="data-ticket-thick">SFO</h2>
                                                <p className="text-ticket">04:48<sup>+1</sup></p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="card cardMiddle">
                                        <div className="row my-4">
                                            <div className="col-6">
                                                <p className="text-ticket">Passageiro</p>
                                                <h5 className="data-ticket">Rodrigo Terron</h5>
                                            </div>
                                            <div className="col-6 text-right">
                                                <p className="text-ticket">Assento</p>
                                                <h5 className="data-ticket">28A</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card cardBottom border-0">
                                        <div className="row my-4">
                                            <div className="col-5">
                                                <div>
                                                    <p className="text-ticket">Embarque</p>
                                                    <h5 className="data-ticket pb-4 time-ticket">16:15</h5>
                                                </div>
                                                <div>
                                                    <p className="text-ticket">Embarque</p>
                                                    <h5 className="data-ticket pb-4">15</h5>
                                                </div>
                                                <div>
                                                    <p className="text-ticket">Portão</p>
                                                    <h5 className="data-ticket pb-4">2</h5>
                                                </div>
                                            </div>
                                            <div className="col-7 text-center">
                                               <img src={Qrcode} className="qrcode" alt="QRcode"/>
                                               <p className="text-ticket pt-2">Grupo de embarque: 3</p>

                                            </div>
                                            <div className="col-12 text-center">
                                                <p className="text-ticket pt-2">Atenção: o portão fecha 16:45</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>





                    </Container>

                </div>
            </div>
        );
    }
}
export default Ticket