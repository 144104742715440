import React from 'react';
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Home from './Home2';  // Import the class component

const queryClient = new QueryClient();

function HomeWrapper() {
  const fetchTripsData = async () => {
    const userId = localStorage.getItem("userId");
    const response = await fetch(`https://fridayis-api.herokuapp.com/api/trip/all/invited?userId=${userId}&joined=1`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  };

  const { data, error, isLoading } = useQuery({
    queryKey: ['tripData'],
    queryFn: fetchTripsData
  });

  return (
    <Home data={data} error={error} isLoading={isLoading} />
  );
}

export default function HomeWithProvider() {
  return (
    <QueryClientProvider client={queryClient}>
      <HomeWrapper />
    </QueryClientProvider>
  );
}
