import React from 'react';
import {Button, Form, FormGroup, Label, Input, Container, Row, Col,
    Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Alert
} from 'reactstrap';
import { Link } from "react-router-dom";
import Logo from '../img/logo_new.png';
import bcrypt from "bcryptjs";

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            firstname: "",
            lastname: "",
            loginWith: "email",
            // tel: "",
            // dob: "",
            // emergency_contact_name: "",
            // emergency_contact_tel: "",

            username: "",
            showStatusSuccess: "none",
            visible: false,
            visibleError: false,
            saveData : true,

            dreamEvent: "",
            dreamDestination: "",
            passportExpire: "",
            passportNo: ""

        };

        this.handleChange = this.handleChange.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
    }

    onDismiss() {
        this.setState({ visible: false });
    }

    handleChange(e){
        switch(e.target.name) {
            case "firstname":
                this.setState({firstname : e.target.value});
                break;
            case "lastname":
                this.setState({lastname : e.target.value});
                break;
            // case "tel":
            //     this.setState({tel : e.target.value});
            //     break;
            // case "dob":
            //     this.setState({dob : e.target.value});
            //     break;
            // case "emergency_contact_name":
            //     this.setState({emergency_contact_name : e.target.value});
            //     break;
            // case "emergency_contact_tel":
            //     this.setState({emergency_contact_tel : e.target.value});
            //     break;
            case "username":
                this.setState({username : e.target.value});
                break;
            case "email":
                this.setState({email : e.target.value});
                break;
            case "password":
                this.setState({password : e.target.value});
                break;
            case "dreamEvent":
                this.setState({dreamEvent : e.target.value});
                break;
            case "dreamDestination":
                this.setState({dreamDestination : e.target.value});
                break;
            case "passportExpire":
                this.setState({passportExpire : e.target.value});
                break;
            case "passportNo":
                this.setState({passportNo : e.target.value});
                break;
            default:
            // code block
        }
    }

    handleSubmit(event) {
        alert(this.state.email + " " + this.state.password + " ");
        event.preventDefault();
    }

    resetInputField() {
        this.setState({email : ''});
        this.setState({password : ''});
        this.setState({firstname : ''});
        this.setState({lastname : ''});
        // this.setState({tel : ''});
        // this.setState({dob : ''});
        // this.setState({emergency_contact_name : ''});
        // this.setState({emergency_contact_tel : ''});
        this.setState({username : ''})
        this.setState({visible : true});
        this.setState({saveData : true});
    }

    saveData(){
        // console.log(this.state);
        // fetch('http://localhost:3001')
        //     .then(response => response.json())
        //     .then(data => alert(data));
        // this.resetInputField();
        if(this.state.firstname === ''){
            document.getElementById("checkFirstname").style.display = "block";
            this.state.saveData = false;
        }else{
            document.getElementById("checkFirstname").style.display = "none";
            this.state.saveData = true;
        }
        if(this.state.lastname === ''){
            document.getElementById("checkSurname").style.display = "block";
            this.state.saveData = false;
        }else{
            document.getElementById("checkSurname").style.display = "none";
            this.state.saveData = true;
        }
        // if(this.state.tel == ''){
        //     document.getElementById("checkTel").style.display = "block";
        //     this.state.saveData = false;
        // }else{
        //     document.getElementById("checkTel").style.display = "none";
        //     this.state.saveData = true;
        // }
        // if(this.state.dob == ''){
        //     document.getElementById("checkDOB").style.display = "block";
        //     this.state.saveData = false;
        // }else{
        //     document.getElementById("checkDOB").style.display = "none";
        //     this.state.saveData = true;
        // }
        if(this.state.username === ''){
            document.getElementById("checkUsername").style.display = "block";
            this.state.saveData = false;
        }else{
            document.getElementById("checkUsername").style.display = "none";
            this.state.saveData = true;
        }
        if(this.state.email === ''){
            document.getElementById("checkEmail").style.display = "block";
            this.state.saveData = false;
        }else{
            document.getElementById("checkEmail").style.display = "none";
            this.state.saveData = true;
        }
        if(this.state.password === ''){
            document.getElementById("checkPassword").style.display = "block";
            this.state.saveData = false;
        }else{
            document.getElementById("checkPassword").style.display = "none";
            this.state.saveData = true;
        }
        if(this.state.saveData === true){

            let salt = bcrypt.genSaltSync(10);
            let hash = bcrypt.hashSync(this.state.password, salt);

            this.setState({password: hash},()=>{
                // fetch('http://localhost:3001/register',
                fetch('https://fridayis-api.herokuapp.com/api/user/register',
                    {
                        body: JSON.stringify(this.state),
                        method: 'POST',
                        headers: {"Content-type": "application/json;charset=UTF-8"}})
                    .then(res => res.json())
                    .then(data => {
                        if(data.message){
                            this.setState({password : ''},()=>{
                                this.setState({visibleError : true},()=>{
                                    this.setState({visible : false},()=>{
                                        document.getElementById('email').focus();
                                    });
                                });
                            });
                        }
                        else{
                            this.setState({email : ''});
                            this.setState({password : ''});
                            this.setState({firstname : ''});
                            this.setState({lastname : ''});
                            // this.setState({tel : ''});
                            // this.setState({dob : ''});
                            // this.setState({emergency_contact_name : ''});
                            // this.setState({emergency_contact_tel : ''});
                            this.setState({username : ''})
                            this.setState({dreamEvent : ''})
                            this.setState({dreamDestination : ''})
                            this.setState({passportExpire : ''})
                            this.setState({passportNo : ''})
                            this.setState({visible : true});
                            this.setState({visibleError : false});
                        }



                    })
                    .catch(error => {
                        this.setState({password : ''},()=>{
                            this.setState({visibleError : true},()=>{
                                this.setState({visible : false},()=>{
                                    document.getElementById('email').focus();
                                });
                            });
                        });
                    });
                // .then(res => res.json())
                // .then(data => this.resetInputField());
            });

        }
      
    }

    render() {
        return (
            <React.Fragment>
            {/*<div style={{*/}
            {/*    backgroundImage: "url(" + Background + ")",*/}
            {/*    height: '100%',*/}
            {/*    backgroundPosition: 'center',*/}
            {/*    backgroundSize: 'cover',*/}
            {/*    backgroundRepeat: 'no-repeat'*/}
            {/*}}>*/}
                <Container>
                    <Row className="d-flex align-items-center justify-content-center mt-5">
                        <Col sm="12" md="5" lg="5" className="mt-5 mb-5">
                            <Card className="border-0 mx-2 p-2 circle-border-small">
                                <div className="text-center">
                                    <CardImg top className="logo" src={Logo} alt="Logo image" />
                                </div>
                                <CardBody>
                                    <CardText className="text-center"><h2>Registration</h2></CardText>
                                    <Alert color="success" isOpen={this.state.visible} toggle={this.onDismiss}>
                                        Success! register an account
                                    </Alert>
                                    <Alert color="danger" isOpen={this.state.visibleError} toggle={this.onDismiss}>
                                        Error! Duplicate email address
                                    </Alert>
                                    <div className="login">
                                        <Form onSubmit={this.handleSubmit}>
                                            <div className="row">
                                                <div className="col-6">
                                                    <FormGroup>
                                                        <Label for="firstname">Firstname <span style={{color: "red"}}>*</span></Label>
                                                        <Input
                                                            type="text"
                                                            name="firstname"
                                                            id="firstname"
                                                            value={this.state.firstname}
                                                            onChange={this.handleChange}
                                                            className="input-login"
                                                        />
                                                        <Label for="checkFirstname" style={{display:  "none",color:"red"}} id ="checkFirstname">Please Enter Firstname </Label>
                                                    </FormGroup>
                                                </div>
                                                <div className="col-6">
                                                    <FormGroup>
                                                        <Label for="lastname">Lastname <span style={{color: "red"}}>*</span></Label>
                                                        <Input
                                                            type="text"
                                                            name="lastname"
                                                            id="lastname"
                                                            value={this.state.lastname}
                                                            onChange={this.handleChange}
                                                            className="input-login"
                                                        />
                                                        <Label for="checkSurname" style={{display:  "none",color:"red"}} id ="checkSurname">Please Enter Lastname </Label>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            {/*<div className="row">*/}
                                            {/*    <div className="col-6">*/}
                                            {/*        <FormGroup>*/}
                                            {/*            <Label for="email">Phone number <span style={{color: "red"}}>*</span></Label>*/}
                                            {/*            <Input*/}
                                            {/*                type="text"*/}
                                            {/*                name="tel"*/}
                                            {/*                id="tel"*/}
                                            {/*                value={this.state.tel}*/}
                                            {/*                onChange={this.handleChange}*/}
                                            {/*            />*/}
                                            {/*             <Label for="checkTel" style={{display:  "none",color:"red"}} id ="checkTel">Please Enter Phone number</Label>*/}
                                            {/*        </FormGroup>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="col-6">*/}
                                            {/*        <FormGroup>*/}
                                            {/*            <Label for="email">Date of birth <span style={{color: "red"}}>*</span></Label>*/}
                                            {/*            <Input*/}
                                            {/*                type="date"*/}
                                            {/*                name="dob"*/}
                                            {/*                id="dob"*/}
                                            {/*                value={this.state.dob}*/}
                                            {/*                onChange={this.handleChange}*/}
                                            {/*            />*/}
                                            {/*            <Label for="checkDOB" style={{display:  "none",color:"red"}} id ="checkDOB">Please Select Date of birth </Label>*/}
                                            {/*        </FormGroup>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}

                                            {/*<FormGroup>*/}
                                            {/*    <Label for="email">Emergency contact name</Label>*/}
                                            {/*    <Input*/}
                                            {/*        type="text"*/}
                                            {/*        name="emergency_contact_name"*/}
                                            {/*        id="emergency_contact_name"*/}
                                            {/*        value={this.state.emergency_contact_name}*/}
                                            {/*        onChange={this.handleChange}*/}
                                            {/*    />*/}
                                            {/*</FormGroup>*/}
                                            {/*<FormGroup>*/}
                                            {/*    <Label for="email">Emergency contact phone number</Label>*/}
                                            {/*    <Input*/}
                                            {/*        type="text"*/}
                                            {/*        name="emergency_contact_tel"*/}
                                            {/*        id="emergency_contact_tel"*/}
                                            {/*        value={this.state.emergency_contact_tel}*/}
                                            {/*        onChange={this.handleChange}*/}
                                            {/*    />*/}
                                            {/*</FormGroup>*/}
                                            <FormGroup>
                                                <Label for="email">Username <span style={{color: "red"}}>*</span></Label>
                                                <Input
                                                    type="text"
                                                    name="username"
                                                    id="username"
                                                    value={this.state.username}
                                                    onChange={this.handleChange}
                                                    className="input-login"
                                                />
                                                <Label for="checkUsername" style={{display:  "none",color:"red"}} id ="checkUsername">Please Enter Username </Label>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="email">Email <span style={{color: "red"}}>*</span></Label>
                                                <Input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    value={this.state.email}
                                                    onChange={this.handleChange}
                                                    className="input-login"
                                                />
                                                <Label for="checkEmail" style={{display:  "none",color:"red"}} id ="checkEmail">Please Enter Email </Label>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="password">Password <span style={{color: "red"}}>*</span></Label>
                                                <Input
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    value={this.state.password}
                                                    onChange={this.handleChange}
                                                    className="input-login"
                                                />
                                                <Label for="checkPassword" style={{display:  "none",color:"red"}} id ="checkPassword">Please Enter Password </Label>                                                
                                            </FormGroup>
                                            <div className="row">
                                                <div className="col-6">
                                                    <FormGroup>
                                                        <Label for="passportExpire">Passport No.</Label>
                                                        <Input
                                                            type="text"
                                                            name="passportNo"
                                                            id="passportNo"
                                                            value={this.state.passportNo}
                                                            onChange={this.handleChange}
                                                            className="input-login"
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-6">
                                                    <FormGroup>
                                                        <Label for="passportExpire">Passport Expire</Label>
                                                        <Input
                                                            type="date"
                                                            name="passportExpire"
                                                            id="passportExpire"
                                                            value={this.state.passportExpire}
                                                            onChange={this.handleChange}
                                                            className="input-login"
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>

                                            <FormGroup>
                                                <Label for="dreamDestination">Your dream Destination</Label>
                                                <Input
                                                    type="text"
                                                    name="dreamDestination"
                                                    id="dreamDestination"
                                                    value={this.state.dreamDestination}
                                                    onChange={this.handleChange}
                                                    className="input-login"
                                                    placeholder="Ex. Switzerland Paris Thailand"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="dreamDestination">Your dream Event</Label>
                                                <Input
                                                    type="text"
                                                    name="dreamEvent"
                                                    id="dreamEvent"
                                                    value={this.state.dreamEvent}
                                                    onChange={this.handleChange}
                                                    className="input-login"
                                                />
                                            </FormGroup>

                                            <Button className="form-control button-ticket" onClick={()=>this.saveData()}>Submit</Button>
                                            <p className="text-center mt-2">Already have an account? <a href="/login">Log in</a></p>
                                        </Form>
                                    </div>
                                    {/*<CardSubtitle>Card subtitle</CardSubtitle>*/}
                                    {/*<CardText>Some quick example text to build on the card title and make up the bulk of the card's content.</CardText>*/}
                                    {/*<Button>Button</Button>*/}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            {/*</div>*/}
            </React.Fragment>
        );
    }
}

export default Register;