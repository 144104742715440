import React from 'react';
import {Container, Navbar, NavbarBrand, Input, Card, Row, Col
    , Collapse, NavbarToggler, Nav, NavItem, NavLink
} from 'reactstrap';
import Slider from "react-slick";

import { FaPlus } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { AiOutlineHeart } from 'react-icons/ai';
import { GrHomeRounded } from 'react-icons/gr';
import { HiOutlineTicket } from 'react-icons/hi';
// import { IoIosArrowDown, IoIosAdd, IoMdChatbubbles, IoIosArrowBack } from 'react-icons/io';
import Logo from '../img/logo_new.png';

class Start extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }

    componentDidMount() {
        // if(!this.state.memberId){
        //     window.location.href = '/login';
        // }
        // else{
        //     this.getTask();
        // }
    }

    render() {
        return (
            // <div className="bg">
                <div>
                    <Container>
                        <div className="center-page">
                            <div className="text-center">
                                <img src={Logo} className="logo" alt="Logo" />
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-md-4 offset-3 offset-md-4">
                                    <h1 className="pt-4">
                                        Make the first <br/> move!
                                    </h1>
                                </div>
                            </div>

                        </div>


                    </Container>
                </div>
            // </div>
        );
    }
}
export default Start