import React, { Component } from 'react';
import Helmet from 'react-helmet';

class HeadTags extends Component {
    render() {
        const {
            title = "Fridayis | All-in-one travel community",
            metaDescription = "Explore the world with our travel guides, tips, and recommendations. Find the best deals on flights, hotels, and activities, and plan your dream vacation today. Whether you're looking for adventure or relaxation, we've got you covered. Start your journey now.",
            metaImage = "https://www.fridayis.com/logo_shared2.png",
            metaUrl = 'https://www.fridayis.com'
        } = this.props;

        return (
            <Helmet htmlAttributes>
                <link rel="icon" type="image/png" href="https://www.fridayis.com/logo.png"/>
                <meta name="theme-color" content="#000000"/>
                <meta name="description" content={metaDescription} />
                <meta name="title" content={title} />
                <link rel="apple-touch-icon" href="https://www.fridayis.com/logo.png" />
                <link rel="canonical" href={metaUrl} />
                <title>{title}</title>

                <meta property="og:url" key="og:url" content={metaUrl} />
                <meta property="og:type" key="og:type" content="article"/>
                <meta property="og:title" key="og:title" content={title} />
                <meta property="og:description" key="og:description" content={metaDescription} />
                <meta property="og:image:secure_url" key="og:image:secure_url" content={metaImage} />
                <meta property="og:site_name" content="Fridayis" />
            </Helmet>
        );
    }
}

export default HeadTags;
