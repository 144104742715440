import React from 'react';
import {
    Button, Form, FormGroup, Label, Input, Container, Row, Col,
    Card, CardImg, CardBody, Alert
} from 'reactstrap';
// import { withRouter, Link } from "react-router-dom";
// import Background from '../img/bg-login.jpg';
import Logo from '../img/logo_new.png';
import {GoogleLogin, GoogleLogout} from 'react-google-login';
import  {gapi} from "gapi-script";
import bcrypt from 'bcryptjs'
class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            visible: false,
            clientId: "537557522412-jf9lucvl7bsg8sbk9lisvv47fqf45nq0.apps.googleusercontent.com",
            profile: [],
            messageShow: "Error! wrong username or password",
            urlctrip: "",
            // apiUrl: "http://localhost:3001",
            apiUrl: "https://fridayis-api.herokuapp.com",
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onFailure = this.onFailure.bind(this);
        // this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        let id = params.get('id');
        let ctrip = params.get('ctrip');
        if(id && ctrip){
            this.setState({urlctrip: "?id="+id+"&ctrip="+ctrip});
        }
        else if(id){
            this.setState({urlctrip: "?id="+id});
        }
        else if(ctrip){
            this.setState({urlctrip: "?ctrip="+ctrip});
        }



        window.gapi.load('auth2', () => {
            window.gapi.auth2.init({
                client_id: this.state.clientId,
                scopr:''
            });
        });
    }

    // logout(){
    //     this.setState({profile: []});
    // }
    onSuccess(response){
        this.setState({profile: response.profileObj},()=>{
            fetch(this.state.apiUrl+'/api/user/google',
                {
                    body: JSON.stringify(this.state.profile),
                    method: 'POST',
                    headers: {"Content-type": "application/json;charset=UTF-8"}})
                .then(res => res.json())
                .then(data => {
                    localStorage.setItem("userId", data[0].UserID);
                    localStorage.setItem("username", data[0].Username);
                    localStorage.setItem("imageUrl", data[0].ProfileURL);


                    const User = {
                        userId: data[0].UserID,
                        username: data[0].Username,
                        imageUrl: data[0].ProfileURL,
                        passportNo: '',
                        passportExpire: '',
                        dreamDestination: '',
                        dreamEvent: '',
                    };

                    localStorage.setItem('userInfo', JSON.stringify(User));


                    const search = window.location.search;
                    const params = new URLSearchParams(search);
                    let id = params.get('id');
                    let ctrip = params.get('ctrip');

                    if(this.state.urlctrip){
                        let dataAddInvited = {
                            "userId": data[0].UserID,
                            "ctrip": ctrip,
                            "status": "invited"

                        };
                        fetch(this.state.apiUrl+'/api/clone/invited/add',
                            // fetch('http://localhost:3001/api/clone/invited/add',
                            {
                                body: JSON.stringify(dataAddInvited),
                                method: 'POST',
                                headers: {"Content-type": "application/json;charset=UTF-8"}})
                            .then(res => res.json())
                            .then(data => {
                                window.setTimeout(function () {
                                    if(id && ctrip){
                                        window.location.href = "/home?id="+id+"&ctrip="+ctrip;
                                    }
                                    else if(id){
                                        window.location.href = "/home?id="+id;
                                    }
                                    else if(ctrip){
                                        window.location.href = "/home?ctrip="+ctrip;
                                    }
                                }, 1000);
                                // if(data.message === "Success"){
                                //     window.setTimeout(function(){
                                //         window.location.href = "/home"+this.state.urlctrip;
                                //     }, 800);
                                // }

                            });
                    }
                    else{
                        window.setTimeout(function(){
                            window.location.href = "/home";
                        }, 1000);
                    }


                })
                .catch(error => {
                    // this.setState({visible : true});
                });
        });

        // console.log('Success', response);
    }

    onFailure(response){
        console.log('Failure', response);
    }

    onDismiss() {
        this.setState({ visible: false });
    }

    handleEmailChange(event) {
        this.setState({ email: event.target.value });
    }

    handlePasswordChange(event) {
        this.setState({ password: event.target.value });
    }

    handleSubmit(event) {
        // alert(this.state.email + " " + this.state.password + " ");
        event.preventDefault();
    }

    saveData(){
        // let salt = bcrypt.genSaltSync(10);
        // let hash = bcrypt.hashSync("pass", salt);
        // console.log(hash);
        if(this.state.email && this.state.password){
            fetch(this.state.apiUrl+'/api/user/login',
                {
                    body: JSON.stringify(this.state),
                    method: 'POST',
                    headers: {"Content-type": "application/json;charset=UTF-8"}})
                .then(res => res.json())
                .then(data => {
                    // this.setState({profile: data});
                    // console.log(data[0]);
                    let pass = data[0].Password;
                    let res = bcrypt.compareSync(this.state.password, pass);

                    if(res === true){
                        localStorage.setItem("userId", data[0].UserID);
                        localStorage.setItem("username", data[0].Username);
                        localStorage.setItem("imageUrl", data[0].ProfileURL);

                        const User = {
                            userId: data[0].UserID,
                            username: data[0].Username,
                            imageUrl: data[0].ProfileURL,
                            passportNo: data[0].PassportNo,
                            passportExpire: data[0].PassportExpire,
                            dreamDestination: data[0].DreamDestination,
                            dreamEvent: data[0].DreamEvent,
                        };

                        localStorage.setItem('userInfo', JSON.stringify(User));

                        const search = window.location.search;
                        const params = new URLSearchParams(search);
                        let id = params.get('id');
                        let ctrip = params.get('ctrip');


                        if(this.state.urlctrip) {
                            let dataAddInvited = {
                                "userId": data[0].UserID,
                                "ctrip": ctrip,
                                "status": "invited"

                            };
                            fetch(this.state.apiUrl+'/api/clone/invited/add',
                                {
                                    body: JSON.stringify(dataAddInvited),
                                    method: 'POST',
                                    headers: {"Content-type": "application/json;charset=UTF-8"}
                                })
                                .then(res => res.json())
                                .then(data => {
                                    // console.log(data)
                                    window.location.href = "/home"+this.state.urlctrip;
                                });
                        }
                        else{
                            window.location.href = "/home";
                        }

                    }
                    else{
                        this.setState({messageShow: "Error! wrong username or password"},()=>{
                            this.setState({visible : true});
                        });
                    }

                })
                .catch(error => {
                    this.setState({messageShow: "Error! wrong username or password"},()=>{
                        this.setState({visible : true});
                    });
                });
        }
        else{
            this.setState({messageShow: "Please input username and password"},()=>{
                this.setState({visible : true});
            });
        }

    }

    render() {
        return (
            // <div style={{
            //     backgroundImage: "url(" + Background + ")",
            //     height: '100vh',
            //     backgroundPosition: 'center',
            //     backgroundSize: 'cover',
            //     backgroundRepeat: 'no-repeat'
            // }}>
            <Container>
                {/*{(!this.state.profile) ? (*/}
                {/*    <GoogleLogin*/}
                {/*        clientId={this.state.clientId}*/}
                {/*        buttonText="Continue with google"*/}
                {/*        onSuccess={this.onSuccess}*/}
                {/*        onFailure={this.onFailure}*/}
                {/*        cookiePolicy={'single_host_origin'}*/}
                {/*        isSignin={true}*/}
                {/*    />*/}
                {/*) : (*/}
                {/*    <div>*/}
                {/*        <img src={this.state.profile.imageUrl} alt="user image" />*/}
                {/*        <p>Name: {this.state.profile.name}</p>*/}
                {/*        <p>Email: {this.state.profile.email}</p>*/}
                {/*        <GoogleLogout clientId={this.state.clientId}  buttonText="Logout" onLogoutSuccess={this.logout}/>*/}
                {/*    </div>*/}

                {/*)}*/}
                <Row className="d-flex align-items-center justify-content-center mt-5">
                    <Col sm="12" md="6" lg="5" className="mt-5">
                        <Card className="border-0 mx-2 p-2 circle-border-small">
                            <div className="text-center">
                                <CardImg top className="logo" src={Logo} alt="Logo image" />
                            </div>

                            <CardBody>
                                {/*<CardText className="text-center"><h2>Login</h2></CardText>*/}
                                {/*<CardTitle>Login</CardTitle>*/}
                                <Alert color="danger" isOpen={this.state.visible} toggle={this.onDismiss}>
                                    {this.state.messageShow}
                                </Alert>
                                <div className="login">
                                    <Form onSubmit={this.handleSubmit}>
                                        {/*<div className="input-group mb-3">*/}
                                        {/*    <span className="input-group-text" id="basic-addon1">*/}
                                        {/*        123*/}
                                        {/*    </span>*/}
                                        {/*    <Input*/}
                                        {/*        type="email"*/}
                                        {/*        name="email"*/}
                                        {/*        id="email"*/}
                                        {/*        value={this.state.email}*/}
                                        {/*        onChange={this.handleEmailChange}*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                            <Input
                                                type="email"
                                                name="email"
                                                id="email"
                                                value={this.state.email}
                                                onChange={this.handleEmailChange}
                                                placeholder="Please enter email"
                                                className="input-login"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="password">Password</Label>
                                            <Input
                                                type="password"
                                                name="password"
                                                id="password"
                                                value={this.state.password}
                                                onChange={this.handlePasswordChange}
                                                placeholder="Please enter password"
                                                className="input-login"
                                            />
                                        </FormGroup>
                                        <FormGroup check className="mb-2">
                                            <Label check>
                                                <Input type="checkbox" /> Remember Me
                                            </Label>
                                        </FormGroup>
                                        <Button className="form-control button-ticket" onClick={()=>this.saveData()}>Login</Button>

                                        <p className="text-center my-2 pt-2"><a href="/register">Create account</a></p>
                                        <GoogleLogin
                                            className="form-control mt-4 button-google-login btn-sm"
                                            clientId={this.state.clientId}
                                            buttonText="Continue with google"
                                            onSuccess={this.onSuccess}
                                            onFailure={this.onFailure}
                                            cookiePolicy={'single_host_origin'}
                                            isSignin={true}
                                        />
                                        {/*<p className="text-center mt-2">Need an account? <a href="/register">Sign up</a></p>*/}
                                    </Form>
                                </div>
                                {/*<CardSubtitle>Card subtitle</CardSubtitle>*/}
                                {/*<CardText>Some quick example text to build on the card title and make up the bulk of the card's content.</CardText>*/}
                                {/*<Button>Button</Button>*/}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Login;